import { createContext } from "react";

const GlobalContext = createContext({
  requestURL: "",
  data: [],
  selectedSidetab: "",
  errorFetchingData: false,
  error: undefined,
  keyList: [],
  labelsArray: [],
  selectedChartType: "",
  graphReadyToBeDrawn: false,
  graphDrawn: false,
  primaryAxisKey: "",
  primaryAxisMin: -Infinity,
  primaryAxisMax: Infinity,
  primaryAxisKeyType: "",
  secondaryAxisKey: "",
  secondaryAxisMin: -Infinity,
  secondaryAxisMax: Infinity,
  secondaryAxisKeyType: "",
  colorKey: undefined,
  colorRange: [],
  parentKey: "_root_",
  graphHeight: 800,
  graphWidth: 450,
  title: "",
  description: "",
  note: "",
  footer: "",
  showTicks: true,
  showLabels: true,
  showValues: false,
  showKeys: true,
  showTooltips: true,
  rotateLabel: false,
  gridColor: "#cccccc",
  labelColor: "#212121",
  baseColor: "#5682a3",
  backgroundColor: "#ffffff",
  labelPositionInside: true,
  reverse: false,
  sort: false,
  useKeys: false,
  keysToUse: [],
  tooltipPosition: { x: 0, y: 0 },
  tooltipData: undefined,
  tickFormat: "none",
  barPadding: 10,
  roundedCorner: 0,
  auth: false,
  updateData: (data: any) => { },
  updateSelectedSidetab: (data: any) => { },
  updateSelectedChartType: (data: any) => { },
  updatePrimaryAxisKey: (data: any) => { },
  updateSecondaryAxisKey: (data: any) => { },
  updatePrimaryAxisKeyType: (data: any) => { },
  updateSecondaryAxisKeyType: (data: any) => { },
  updateColorKey: (data: any) => { },
  updatePrimaryAxisMin: (data: any) => { },
  updatePrimaryAxisMax: (data: any) => { },
  updateSecondaryAxisMin: (data: any) => { },
  updateSecondaryAxisMax: (data: any) => { },
  updateColorRange: (data: any) => { },
  updateParentKey: (data: any) => { },
  updateGraphHeight: (data: any) => { },
  updateGraphWidth: (data: any) => { },
  updateTitle: (data: any) => { },
  updateDescription: (data: any) => { },
  updateNote: (data: any) => { },
  updateFooter: (data: any) => { },
  updateShowTicks: (data: any) => { },
  updateShowLabels: (data: any) => { },
  updateShowValues: (data: any) => { },
  updateShowKeys: (data: any) => { },
  updateShowTooltips: (data: any) => { },
  updateGridColor: (data: any) => { },
  updateLabelColor: (data: any) => { },
  updateBaseColor: (data: any) => { },
  updateBackgroundColor: (data: any) => { },
  updateErrorFetchingData: (data: any) => { },
  updateError: (data: any) => { },
  updateRequestURL: (data: any) => { },
  updateGraphReadyToBeDrawn: (data: any) => { },
  updateGraphDrawn: (data: any) => { },
  updateRotateLabel: (data: any) => { },
  updateLabelPositionInside: (data: any) => { },
  updateReverse: (data: any) => { },
  updateSort: (data: any) => { },
  updateKeysToUse: (data: any) => { },
  updateLabelsArray: (data: any) => { },
  updateTooltipPosition: (data: any) => { },
  updateTooltipData: (data: any) => { },
  updateTickFormat: (data: any) => { },
  updateBarPadding: (data: any) => { },
  updateRoundedCorner: (data: any) => { },
});

export default GlobalContext;
