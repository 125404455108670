import React from "react";

interface Props {
  width: number;
  height: number;
  color?: string;
}

export const DataTableIcon = (props: Props) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 520 520"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="36"
      y="36"
      width="448"
      height="448"
      stroke={props.color ? props.color : "#2c2c2c"}
      strokeWidth="15"
    />
    <rect
      x="36"
      y="109"
      width="150"
      height="375"
      stroke={props.color ? props.color : "#2c2c2c"}
      strokeWidth="15"
    />
    <rect
      x="36"
      y="109"
      width="448"
      height="75"
      stroke={props.color ? props.color : "#2c2c2c"}
      strokeWidth="15"
    />
    <rect
      x="36"
      y="184"
      width="448"
      height="75"
      stroke={props.color ? props.color : "#2c2c2c"}
      strokeWidth="15"
    />
    <rect
      x="36"
      y="259"
      width="448"
      height="75"
      stroke={props.color ? props.color : "#2c2c2c"}
      strokeWidth="15"
    />
    <rect
      x="36"
      y="334"
      width="448"
      height="75"
      stroke={props.color ? props.color : "#2c2c2c"}
      strokeWidth="15"
    />
    <rect
      x="36"
      y="409"
      width="448"
      height="75"
      stroke={props.color ? props.color : "#2c2c2c"}
      strokeWidth="15"
    />
    <rect
      x="334"
      y="109"
      width="150"
      height="375"
      stroke={props.color ? props.color : "#2c2c2c"}
      strokeWidth="15"
    />
  </svg>
);

export const GraphStyleIcon = (props: Props) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 520 520"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M143.089 408.067C219.863 475.579 315.435 500.876 387.642 477.473C412.154 469.15 425.223 442.692 416.83 418.385C416.076 416.195 415.156 414.068 414.08 412.011L381.858 349.424C375.924 337.913 378.149 323.925 387.359 314.776C396.821 305.393 411.392 303.343 423.117 309.734L439.337 318.586C454.528 326.925 473.657 321.478 482.066 306.414C485.65 299.993 486.836 292.518 485.414 285.318C473.964 232.341 448.643 183.291 412.004 143.102L379.782 111.15C279.771 23.7814 148.268 7.85296 77.947 77.5858C9.45716 145.502 22.9345 270.979 105.045 369.103L143.089 408.067Z"
      stroke={props.color ? props.color : "#2c2c2c"}
      strokeWidth="15"
    />
    <path
      d="M320.706 136.103C320.706 150.795 308.795 162.706 294.103 162.706C279.411 162.706 267.5 150.795 267.5 136.103C267.5 121.411 279.411 109.5 294.103 109.5C308.795 109.5 320.706 121.411 320.706 136.103Z"
      stroke={props.color ? props.color : "#2c2c2c"}
      strokeWidth="15"
    />
    <path
      d="M189.706 102.103C189.706 116.795 177.795 128.706 163.103 128.706C148.411 128.706 136.5 116.795 136.5 102.103C136.5 87.4106 148.411 75.5 163.103 75.5C177.795 75.5 189.706 87.4106 189.706 102.103Z"
      stroke={props.color ? props.color : "#2c2c2c"}
      strokeWidth="15"
    />
    <path
      d="M121.706 192.103C121.706 206.795 109.795 218.706 95.103 218.706C80.4106 218.706 68.5 206.795 68.5 192.103C68.5 177.411 80.4106 165.5 95.103 165.5C109.795 165.5 121.706 177.411 121.706 192.103Z"
      stroke={props.color ? props.color : "#2c2c2c"}
      strokeWidth="15"
    />
    <path
      d="M168.706 307.103C168.706 321.795 156.795 333.706 142.103 333.706C127.411 333.706 115.5 321.795 115.5 307.103C115.5 292.411 127.411 280.5 142.103 280.5C156.795 280.5 168.706 292.411 168.706 307.103Z"
      stroke={props.color ? props.color : "#2c2c2c"}
      strokeWidth="15"
    />
    <path
      d="M286.706 401.103C286.706 415.795 274.795 427.706 260.103 427.706C245.411 427.706 233.5 415.795 233.5 401.103C233.5 386.411 245.411 374.5 260.103 374.5C274.795 374.5 286.706 386.411 286.706 401.103Z"
      stroke={props.color ? props.color : "#2c2c2c"}
      strokeWidth="15"
    />
    <path
      d="M425.18 225.84C425.18 238.178 415.178 248.18 402.84 248.18C390.502 248.18 380.5 238.178 380.5 225.84C380.5 213.502 390.502 203.5 402.84 203.5C415.178 203.5 425.18 213.502 425.18 225.84Z"
      stroke={props.color ? props.color : "#2c2c2c"}
      strokeWidth="15"
    />
  </svg>
);

export const GraphTypeIcon = (props: Props) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 520 520"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="36"
      y="219"
      width="64"
      height="265"
      stroke={props.color ? props.color : "#2c2c2c"}
      strokeWidth="15"
    />
    <rect
      x="132"
      y="267"
      width="64"
      height="217"
      stroke={props.color ? props.color : "#2c2c2c"}
      strokeWidth="15"
    />
    <rect
      x="228"
      y="187"
      width="64"
      height="297"
      stroke={props.color ? props.color : "#2c2c2c"}
      strokeWidth="15"
    />
    <rect
      x="324"
      y="331"
      width="64"
      height="153"
      stroke={props.color ? props.color : "#2c2c2c"}
      strokeWidth="15"
    />
    <rect
      x="420"
      y="214"
      width="64"
      height="270"
      stroke={props.color ? props.color : "#2c2c2c"}
      strokeWidth="15"
    />
    <path
      d="M367.825 223L440.724 84M343.423 227L273.435 114M244 109.353L179.193 184M145 186.4L86 139.511"
      stroke={props.color ? props.color : "#2c2c2c"}
      strokeWidth="15"
    />
    <circle
      cx="68"
      cy="125"
      r="31"
      stroke={props.color ? props.color : "#2c2c2c"}
      strokeWidth="15"
    />
    <circle
      cx="164"
      cy="202"
      r="31"
      stroke={props.color ? props.color : "#2c2c2c"}
      strokeWidth="15"
    />
    <circle
      cx="260"
      cy="91"
      r="31"
      stroke={props.color ? props.color : "#2c2c2c"}
      strokeWidth="15"
    />
    <circle
      cx="356"
      cy="249"
      r="31"
      stroke={props.color ? props.color : "#2c2c2c"}
      strokeWidth="15"
    />
    <circle
      cx="452"
      cy="63"
      r="31"
      stroke={props.color ? props.color : "#2c2c2c"}
      strokeWidth="15"
    />
  </svg>
);

export const BarGraphIcon = (props: Props) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 520 520"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="36"
      y="197"
      width="64"
      height="287"
      fill={props.color ? props.color : "#AD9BFF"}
    />
    <rect
      x="132"
      y="235"
      width="64"
      height="249"
      fill={props.color ? props.color : "#AD9BFF"}
    />
    <rect
      x="228"
      y="52"
      width="64"
      height="432"
      fill={props.color ? props.color : "#AD9BFF"}
    />
    <rect
      x="324"
      y="350"
      width="64"
      height="134"
      fill={props.color ? props.color : "#AD9BFF"}
    />
    <rect
      x="420"
      y="142"
      width="64"
      height="342"
      fill={props.color ? props.color : "#AD9BFF"}
    />
  </svg>
);

export const LineGraphIcon = (props: Props) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 520 520"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M368.825 327L441.724 188M344.423 331L274.435 218M245 213.353L180.193 288M146 290.4L87 243.511"
      stroke={props.color ? props.color : "#AD9BFF"}
      strokeWidth="15"
      strokeLinejoin="round"
    />
    <circle
      cx="69"
      cy="229"
      r="31"
      stroke={props.color ? props.color : "#AD9BFF"}
      strokeWidth="15"
    />
    <circle
      cx="165"
      cy="306"
      r="31"
      stroke={props.color ? props.color : "#AD9BFF"}
      strokeWidth="15"
    />
    <circle
      cx="261"
      cy="195"
      r="31"
      stroke={props.color ? props.color : "#AD9BFF"}
      strokeWidth="15"
    />
    <circle
      cx="357"
      cy="353"
      r="31"
      stroke={props.color ? props.color : "#AD9BFF"}
      strokeWidth="15"
    />
    <circle
      cx="453"
      cy="167"
      r="31"
      stroke={props.color ? props.color : "#AD9BFF"}
      strokeWidth="15"
    />
  </svg>
);

export const SettingsIcon = (props: Props) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 520 520"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="260"
      cy="267"
      r="66.5"
      stroke={props.color ? props.color : "#2c2c2c"}
      strokeWidth="15"
    />
    <path
      d="M448.696 219.826H413.372C410.409 210.155 406.532 200.815 401.824 191.918L426.779 166.963C432.119 161.623 435.062 154.509 435.062 146.933C435.062 139.366 432.119 132.252 426.779 126.912L400.078 100.212C389.408 89.5412 370.727 89.5129 360.037 100.212L335.082 125.167C326.194 120.459 316.845 116.591 307.174 113.619V78.3043C307.174 62.6992 294.475 50 278.87 50H241.13C225.525 50 212.826 62.6992 212.826 78.3043V113.628C203.155 116.6 193.815 120.468 184.918 125.176L159.954 100.221C148.915 89.1827 130.951 89.1827 119.912 100.221L93.2025 126.922C82.1732 137.96 82.1732 155.924 93.2025 166.963L118.157 191.927C113.45 200.815 109.581 210.165 106.609 219.836H71.3043C55.6992 219.836 43 232.535 43 248.14V285.879C43 301.475 55.6992 314.174 71.3043 314.174H106.628C109.6 323.845 113.468 333.185 118.176 342.082L93.2213 367.046C82.1827 378.085 82.1827 396.049 93.2213 407.088L119.922 433.798C130.96 444.827 148.924 444.827 159.963 433.798L184.927 408.843C193.815 413.55 203.165 417.419 212.836 420.391V455.696C212.836 471.301 225.535 484 241.14 484H278.879C294.484 484 307.183 471.301 307.183 455.696V420.372C316.854 417.4 326.204 413.532 335.091 408.824L360.046 433.788C370.745 444.478 389.426 444.459 400.088 433.788L426.788 407.088C432.128 401.748 435.072 394.634 435.072 387.067C435.072 379.491 432.128 372.387 426.788 367.046L401.833 342.082C406.541 333.194 410.409 323.845 413.381 314.174H448.696C464.301 314.174 477 301.475 477 285.87V248.13C477 232.525 464.301 219.826 448.696 219.826Z"
      stroke={props.color ? props.color : "#2c2c2c"}
      strokeWidth="15"
      strokeLinejoin="round"
    />
  </svg>
);
export const LineAndBarChartIcon = (props: Props) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 520 520"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="36"
      y="219"
      width="64"
      height="265"
      fill={props.color ? props.color : "#AD9BFF"}
    />
    <rect
      x="132"
      y="267"
      width="64"
      height="217"
      fill={props.color ? props.color : "#AD9BFF"}
    />
    <rect
      x="228"
      y="187"
      width="64"
      height="297"
      fill={props.color ? props.color : "#AD9BFF"}
    />
    <rect
      x="324"
      y="331"
      width="64"
      height="153"
      fill={props.color ? props.color : "#AD9BFF"}
    />
    <rect
      x="420"
      y="214"
      width="64"
      height="270"
      fill={props.color ? props.color : "#AD9BFF"}
    />
    <path
      d="M367.825 223L440.724 84M343.423 227L273.435 114M244 109.353L179.193 184M145 186.4L86 139.511"
      stroke={props.color ? props.color : "#AD9BFF"}
      strokeWidth="15"
      strokeLinejoin="round"
    />
    <circle
      cx="68"
      cy="125"
      r="31"
      stroke={props.color ? props.color : "#AD9BFF"}
      strokeWidth="15"
    />
    <circle
      cx="164"
      cy="202"
      r="31"
      stroke={props.color ? props.color : "#AD9BFF"}
      strokeWidth="15"
    />
    <circle
      cx="260"
      cy="91"
      r="31"
      stroke={props.color ? props.color : "#AD9BFF"}
      strokeWidth="15"
    />
    <circle
      cx="356"
      cy="249"
      r="31"
      stroke={props.color ? props.color : "#AD9BFF"}
      strokeWidth="15"
    />
    <circle
      cx="452"
      cy="63"
      r="31"
      stroke={props.color ? props.color : "#AD9BFF"}
      strokeWidth="15"
    />
  </svg>
);

export const Tick = (props: Props) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 520 520"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.8059 272.743C17.2542 274.306 17.2574 276.829 18.8131 278.387L183.113 443.001L501.149 125.278C502.72 123.709 502.712 121.16 501.131 119.601L460.758 79.7862C459.193 78.2432 456.677 78.2513 455.123 79.8041L183.113 351.498L64.2691 232.656C62.7031 231.09 60.1627 231.094 58.6023 232.666L18.8059 272.743Z"
      fill={props.color ? props.color : "#2c2c2c"}
    />
  </svg>
);
